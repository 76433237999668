import {Box, Typography} from '@mui/material';
import './../cursor.css'
import ImageCarousel from "./ImageCarousel";

function MyProjects({projects, checked}) {
    return (
        <>
            <Box sx={{textAlign: 'center', my: 4, marginTop: 10}}>
                <Typography variant="h3" sx={{mb: 2, fontFamily: 'jetbrains-bold'}}>
                    {checked ? 'I Miei Progetti' : 'My Projects'}
                </Typography>
            </Box>
            {projects.map(p => {
                return (
                    <ImageCarousel link={p.link} primary={p.primary} secondary={p.secondary} images={p.images} technology={p.technology} icon={p.icon}/>
                );
            })}
        </>
    );
}

export default MyProjects;
