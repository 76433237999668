class TextENG{
    static nome = 'Flavio';
    static cognome = 'Ceccarelli';
    static professione = 'Java Backend Developer';
    static passioni = 'technology, computer science, operating systems, programming, and web and application development';
    static hobby = 'always discovering new things';
    static studi = 'at the ITIS Pacinotti Archimede in Rome (2014-2019)';
    static esperienzeLavoroPassate = 'Junior Java Backend Developer at Prisma s.r.l. from November 2019 (right after obtaining the diploma) to March 2021';
    static lavoroAttuale = 'i work at Softlab s.p.a. as a Java Backend Developer MID (From March 2021 to present)';
    static altro = ' In addition, I am very dedicated to personal growth in the context of my work. I create websites and applications entirely independently and as a self-taught individual.';
    static textAboutMe = 'Hello, I am [nome] [cognome], I am [anni] years old, and i work as a [professione]. ' +
        'I love everything related to development in general and I am passionate about [passioni]. I enjoy [hobby]. ' +
        'I have a degree in [studi] and I have worked in [esperienzeLavoroPassate]. ' +
        'Currently, I am [lavoroAttuale].[altro]';
}

export default TextENG;
