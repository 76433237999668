import React from 'react';
import {Box, Grid, Link, Typography} from '@mui/material';
import Projects from "../utility/Projects";

const IphoneMockupMyProjects = ({projects, checked, showCursor}) => {
    return (
        <center>
            <Box sx={{textAlign: 'center', my: 4, marginTop: 10}}>
                <Typography variant="h3" sx={{mb: 2, fontFamily: 'jetbrains-bold'}}>
                    {checked ? 'I Miei Progetti' : 'My Projects'}<span className="cursor" style={{opacity: showCursor ? 1 : 0, height: '40px'}}/>
                </Typography>
            </Box>
            <div
                style={{
                    padding: '10px',
                    backgroundImage: `url(https://iili.io/J86ooQf.png)`, // Imposta il link dell'immagine come sfondo
                    backgroundSize: '100%', // Ingrandisci leggermente l'immagine
                    backgroundPosition: 'center', // Centra l'immagine
                    backgroundRepeat: 'no-repeat', // Non ripetere l'immagine
                    borderRadius: '20px',
                    width: '300px',
                    height: '650px',
                    position: 'relative' // Imposta la posizione a relativa per il contenuto all'interno
                }}
            >
                <Grid container spacing={2}
                      style={{padding: '10px', marginTop: 40}}> {/* Aggiungi padding per distanziare dal bordo */}
                    {projects.map((app, index) => (
                        app.icon !== null && (
                            // app.icon === null && (
                            <Grid item xs={4} key={index}
                                  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Link href={app.link} target="_blank">
                                    <img
                                        src={app.icon}
                                        alt={app.primary}
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '15%',
                                            marginBottom: '5px',
                                            border: app.primary.includes("Discontinued") || app.primary.includes("Dismessa") ? '2px solid darkred' : '1px solid #e0e0e0',
                                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)' // Aggiunto ombra
                                        }}
                                    />
                                </Link>
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: '#333',
                                        fontFamily: 'jetbrains-bold',
                                        textAlign: 'center',
                                        fontSize: 10,
                                        // backgroundColor: app.primary.includes("Discontinued") || app.primary.includes("Dismessa") ? 'darkred' : '#fff',
                                        padding: '2px 4px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    {app.primary}
                                </Typography>
                            </Grid>
                        )
                    ))}
                </Grid>
            </div>
        </center>
    );
};

export default IphoneMockupMyProjects;
