import Images from "./Images";

class ProjectsENG {
    static projects = [
        {
            technology: 'React JS',
            primary: 'Pillole Poetiche',
            link: "https://pillolepoetiche.netlify.app/",
            icon: Images.pillolePoeticheIcon,
            secondary: 'Application that contains a series of small poems or pieces of them.',
            images: [
            ],
        },
        {
            technology: 'React JS',
            primary: 'Bagget',
            link: "https://bagget.netlify.app/",
            icon: Images.baggetIcon,
            secondary: 'Application to manage each month\'s expenses, broken down by category.',
            images: [
            ],
        },
        {
            technology: 'React JS',
            primary: 'CTV',
            link: 'https://ctvapp.netlify.app/',
            icon: Images.ctvIcon,
            secondary: "CTV is a 50-30-20 Financial Calculator developed with React JS, which helps to allocate monthly income according to the 50-30-20 rule. The interface is intuitive, the data is stored locally for maximum privacy, and there are no requests to external servers. It accepts contributions, has a signed Android app and an online version.",
            images: [
            ],
        },
        {
            technology: 'React JS',
            icon: Images.arrostocinoIcon,
            primary: 'L\'arrosticino',
            link: 'https://larrosticino.netlify.app/',
            secondary: 'Website for a restaurant developed using React JS and @mui-material as a graphics library.',
            images: [
                { src: Images.arrosticino1, alt: "Descrizione dell'immagine" },
            ],
        },
        {
            icon: Images.musicRelationIcon,
            technology: 'React JS',
            primary: 'The Music Relation',
            link: 'https://bright-swan-086dcc.netlify.app/',
            secondary: 'Website for a duo of musicians developed using React JS and @mui-material as a graphics library.',
            images: [
                { src: Images.musicRelation1, alt: "Descrizione dell'immagine" },
            ],
        },
        {
            technology: 'React Native (Expo CLI)',
            icon: Images.precioIcon,
            primary: 'Precio (Discontinued)',
            link: null,
            secondary: 'Application for managing expenses and income developed using React Native with Expo CLI.',
            images: [
                { src: Images.precio1, alt: "Descrizione dell'immagine" },
                { src: Images.precio2, alt: "Descrizione dell'immagine" },
                { src: Images.precio3, alt: "Descrizione dell'immagine" },
                { src: Images.precio4, alt: "Descrizione dell'immagine" },
            ],
        },
        {
            technology: 'React Native, React JS (Web Frontend), Express JS (Backend), MongoDB (Database)',
            icon: Images.monetoAppIcon,
            primary: 'Moneto (Discontinued)',
            link: null,
            secondary: 'Application for managing expenses, income and subscriptions developed using React Native. It can be considered a 2.0 version of Precio, more fluid and optimised for iOS. There is also a web version (discontinued)',
            images: [
                { src: Images.darthSaver1, alt: "Descrizione dell'immagine" },
                { src: Images.darthSaver2, alt: "Descrizione dell'immagine" },
                { src: Images.darthSaver3, alt: "Descrizione dell'immagine" },
                { src: Images.darthSaver4, alt: "Descrizione dell'immagine" },
                { src: Images.darthSaver5, alt: "Descrizione dell'immagine" },
                { src: Images.darthSaver6, alt: "Descrizione dell'immagine" },
            ],
        },
    ];
}

export default ProjectsENG;
