import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import {Email, GitHub, LinkedIn, Twitter, Code, Coffee} from '@mui/icons-material';

function SocialContacts({checked}) {
    const socialContacts = [
        { icon: <GitHub />, label: "github.com/carellice", url: "https://github.com/carellice" },
        { icon: <LinkedIn />, label: "linkedin.com/in/flavio-ceccarelli", url: "https://it.linkedin.com/in/flavio-ceccarelli-38473a1b9?original_referer=https%3A%2F%2Fwww.google.com%2F" },
        { icon: <Twitter />, label: "@ccarellicee", url: "https://twitter.com/ccarellicee" },
        { icon: <Email />, label: "flavio.cecca00@gmail.com", url: "mailto:flavio.cecca00@gmail.com" },
        { icon: <Code />, label: "npmjs.com/~ccarellice", url: "https://www.npmjs.com/~ccarellice" },
        { icon: <Coffee />, label: checked ? "Offrimi un caffè ☕️❤️🙏🏻 " : "Offer me a coffee ☕️❤️🙏🏻 ", url: "https://www.paypal.com/paypalme/flaviocecca" },
    ];

    return (
        <Box sx={{ textAlign: 'center', my: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontFamily: 'jetbrains-bold' }}>
                {checked ? 'Social e Contatti' : 'Social and Contacts'}
            </Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mx: 'auto' }}>
                {socialContacts.map((contact, index) => (
                    <>
                        <ListItem button component="a" href={contact.url} target="_blank" rel="noopener noreferrer">
                            <ListItemIcon>{contact.icon}</ListItemIcon>
                            <ListItemText primaryTypographyProps={{ style: { fontFamily: 'jetbrains-regular' } }} primary={contact.label} />
                        </ListItem>
                        {index !== socialContacts.length - 1 && <Divider />}
                    </>
                ))}
            </List>
        </Box>
    );
}

export default SocialContacts;
