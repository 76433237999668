class Text{
    static nome = 'Flavio';
    static cognome = 'Ceccarelli';
    static professione = 'Java Backend Developer';
    static passioni = 'tecnologia, informatica, sistemi operativi, programmazione e sviluppo di siti web e applicazioni';
    static hobby = 'sempre scoprire cose nuove';
    static studi = 'all\'istituto tecnico informatico Pacinotti Archimede di Roma (2014-2019)';
    static esperienzeLavoroPassate = 'Java Backend Developer Junior presso l\'azienda Prisma s.r.l. da Novembre 2019 (subito dopo aver conseguito il diploma) a Marzo 2021';
    static lavoroAttuale = 'lavoro presso l\'azienda Softlab s.p.a. come Java Backend Developer MID (Da Marzo 2021 ad Oggi)';
    static altro = ' Inoltre mi dedico molto alla crescita personale in ambito del mio lavoro. Creo siti web e applicazioni in maniera del tutto indipendente e da autodidatta.';
    static textAboutMe = 'Ciao, sono [nome] [cognome], ' +
        'ho [anni] anni, sono un [professione] di professione e mi piace tutto cio\' che è lo sviluppo in generale. Sono appassionato di [passioni], ' +
        'e amo [hobby]. Sono diplomato [studi]. Ho lavorato come [esperienzeLavoroPassate]. ' +
        'Attualmente [lavoroAttuale].[altro]';
    static myBirthday = '2000-09-22';
    static calculateAge = async () => {
        const birthDate = new Date(Text.myBirthday);
        const currentDate = new Date();
        let birthDateCurrentYear = new Date(birthDate);
        birthDateCurrentYear.setFullYear(currentDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());
        const currentYear = currentDate.getFullYear();
        const birthYear = birthDate.getFullYear();
        let age = currentYear - birthYear;
        if(birthDateCurrentYear > currentDate){
            age = age - 1;
        }
        return age;
    }
}

export default Text;
