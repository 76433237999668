import React from 'react';
import {Box, Grid, Link, Typography} from '@mui/material';
import Projects from "../utility/Projects";
import Images from "../utility/Images";

const IphoneMockupSocial = ({checked, showCursor}) => {
    return (
        <center>
            <Box sx={{textAlign: 'center', my: 4, marginTop: 10}}>
                <Typography variant="h3" sx={{mb: 2, fontFamily: 'jetbrains-bold'}}>
                    {checked ? 'Social e Contatti' : 'Social and Contacts'}<span className="cursor" style={{opacity: showCursor ? 1 : 0, height: '40px'}}/>
                </Typography>
            </Box>
            <div
                style={{
                    padding: '10px',
                    backgroundImage: `url(https://iili.io/J86ooQf.png)`, // Imposta il link dell'immagine come sfondo
                    backgroundSize: '100%', // Ingrandisci leggermente l'immagine
                    backgroundPosition: 'center', // Centra l'immagine
                    backgroundRepeat: 'no-repeat', // Non ripetere l'immagine
                    borderRadius: '20px',
                    width: '300px',
                    height: '650px',
                    position: 'relative' // Imposta la posizione a relativa per il contenuto all'interno
                }}
            >
                <Grid container spacing={2} style={{padding: '10px', marginTop: 40}}> {/* Aggiungi padding per distanziare dal bordo */}
                    {/*GITHUB*/}
                    <Grid item xs={4}
                          style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Link href={"https://github.com/carellice"} target="_blank">
                            <img
                                src={Images.githubIcon}
                                alt={"GitHub"}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '15%',
                                    marginBottom: '5px',
                                    border: '1px solid #e0e0e0',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)' // Aggiunto ombra
                                }}
                            />
                        </Link>
                        <Typography
                            variant="caption"
                            style={{
                                color: '#333',
                                fontFamily: 'jetbrains-bold',
                                textAlign: 'center',
                                fontSize: 10,
                                // backgroundColor: app.primary.includes("Discontinued") || app.primary.includes("Dismessa") ? 'darkred' : '#fff',
                                padding: '2px 4px',
                                borderRadius: '5px'
                            }}
                        >
                            {"GitHub"}
                        </Typography>
                    </Grid>
                    {/*LINKEDIN*/}
                    <Grid item xs={4}
                          style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Link href={"https://it.linkedin.com/in/flavio-ceccarelli-38473a1b9?original_referer=https%3A%2F%2Fwww.google.com%2F"} target="_blank">
                            <img
                                src={Images.linkedinIcon}
                                alt={"Linkedin"}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '15%',
                                    marginBottom: '5px',
                                    border: '1px solid #e0e0e0',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)' // Aggiunto ombra
                                }}
                            />
                        </Link>
                        <Typography
                            variant="caption"
                            style={{
                                color: '#333',
                                fontFamily: 'jetbrains-bold',
                                textAlign: 'center',
                                fontSize: 10,
                                // backgroundColor: app.primary.includes("Discontinued") || app.primary.includes("Dismessa") ? 'darkred' : '#fff',
                                padding: '2px 4px',
                                borderRadius: '5px'
                            }}
                        >
                            {"Linkedin"}
                        </Typography>
                    </Grid>
                    {/*TWITTER*/}
                    <Grid item xs={4}
                          style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Link href={"https://twitter.com/ccarellicee"} target="_blank">
                            <img
                                src={Images.twitterIcon}
                                alt={"Twitter (X)"}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '15%',
                                    marginBottom: '5px',
                                    border: '1px solid #e0e0e0',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)' // Aggiunto ombra
                                }}
                            />
                        </Link>
                        <Typography
                            variant="caption"
                            style={{
                                color: '#333',
                                fontFamily: 'jetbrains-bold',
                                textAlign: 'center',
                                fontSize: 10,
                                // backgroundColor: app.primary.includes("Discontinued") || app.primary.includes("Dismessa") ? 'darkred' : '#fff',
                                padding: '2px 4px',
                                borderRadius: '5px'
                            }}
                        >
                            {"Twitter (X)"}
                        </Typography>
                    </Grid>
                    {/*EMAIL*/}
                    <Grid item xs={4}
                          style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Link href={"mailto:flavio.cecca00@gmail.com"} target="_blank">
                            <img
                                src={Images.emailIcon}
                                alt={"Email"}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '15%',
                                    marginBottom: '5px',
                                    border: '1px solid #e0e0e0',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)' // Aggiunto ombra
                                }}
                            />
                        </Link>
                        <Typography
                            variant="caption"
                            style={{
                                color: '#333',
                                fontFamily: 'jetbrains-bold',
                                textAlign: 'center',
                                fontSize: 10,
                                // backgroundColor: app.primary.includes("Discontinued") || app.primary.includes("Dismessa") ? 'darkred' : '#fff',
                                padding: '2px 4px',
                                borderRadius: '5px'
                            }}
                        >
                            {"Email"}
                        </Typography>
                    </Grid>
                    {/*NPM*/}
                    <Grid item xs={4}
                          style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Link href={"https://www.npmjs.com/~ccarellice"} target="_blank">
                            <img
                                src={Images.npmIcon}
                                alt={"Npm"}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '15%',
                                    marginBottom: '5px',
                                    border: '1px solid #e0e0e0',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)' // Aggiunto ombra
                                }}
                            />
                        </Link>
                        <Typography
                            variant="caption"
                            style={{
                                color: '#333',
                                fontFamily: 'jetbrains-bold',
                                textAlign: 'center',
                                fontSize: 10,
                                // backgroundColor: app.primary.includes("Discontinued") || app.primary.includes("Dismessa") ? 'darkred' : '#fff',
                                padding: '2px 4px',
                                borderRadius: '5px'
                            }}
                        >
                            {"Npm"}
                        </Typography>
                    </Grid>
                    {/*CAFFE'*/}
                    <Grid item xs={4}
                          style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Link href={"https://www.paypal.com/paypalme/flaviocecca"} target="_blank">
                            <img
                                src={Images.coffeeIcon}
                                alt={"Donazione (Grazie ❤)"}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '15%',
                                    marginBottom: '5px',
                                    border: '1px solid #e0e0e0',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)' // Aggiunto ombra
                                }}
                            />
                        </Link>
                        <Typography
                            variant="caption"
                            style={{
                                color: '#333',
                                fontFamily: 'jetbrains-bold',
                                textAlign: 'center',
                                fontSize: 10,
                                // backgroundColor: app.primary.includes("Discontinued") || app.primary.includes("Dismessa") ? 'darkred' : '#fff',
                                padding: '2px 4px',
                                borderRadius: '5px'
                            }}
                        >
                            {checked ? "Donazione (Grazie ❤)" : "Donation (Thank You ❤)"}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </center>
    );
};

export default IphoneMockupSocial;
