import {Box, Grid, Switch, Tooltip, Typography} from '@mui/material';
import './../cursor.css'

function LanguageSwitch({checked, setChecked, updateAboutMeText, isWritingAboutMe}) {

    return (
        <Box sx={{textAlign: 'center', my: 4}}>
            <Typography variant="h4" sx={{mb: 2, fontFamily: 'jetbrains-bold'}}>
                {/*{checked ? 'Lingua' : 'Language'} {checked ? '🇮🇹' : '🇬🇧'}*/}
                {checked ? 'Lingua' : 'Language'}
            </Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                {isWritingAboutMe ? (
                    <Tooltip title={checked ? 'Devi attendere il caricamento del testo \'Chi sono\' per modificare la lingua' : 'You need to wait for the \'About me\' text to finish loading before you can change the language.'} size="lg" arrow color="danger">
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ mr: 1, fontFamily: 'jetbrains-bold' }}>ENG</Typography>
                            <Switch
                                disabled={isWritingAboutMe}
                                checked={checked}
                                onChange={() => {
                                    const newChecked = !checked;
                                    updateAboutMeText(newChecked);
                                    setChecked(newChecked);
                                    localStorage.setItem('checked', newChecked.toString());
                                }}
                                inputProps={{ 'aria-label': 'Toggle switch' }}
                            />
                            <Typography sx={{ ml: 1, fontFamily: 'jetbrains-bold' }}>ITA</Typography>
                        </Grid>
                    </Tooltip>
                ) : (
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ mr: 1, fontFamily: 'jetbrains-bold'  }}>ENG</Typography>
                        <Switch
                            disabled={isWritingAboutMe}
                            checked={checked}
                            onChange={() => {
                                const newChecked = !checked;
                                updateAboutMeText(newChecked);
                                setChecked(newChecked);
                                localStorage.setItem('checked', newChecked.toString());
                            }}
                            inputProps={{ 'aria-label': 'Toggle switch' }}
                        />
                        <Typography sx={{ ml: 1, fontFamily: 'jetbrains-bold'  }}>ITA</Typography>
                    </Grid>
                )}

            </Grid>
        </Box>
    );
}

export default LanguageSwitch;
