import { Box, Typography } from '@mui/material';
import Images from "../utility/Images";

function Profile() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
                component="img"
                src={Images.memoji}
                sx={{ width: 200, height: 200, borderRadius: '50%', boxShadow: '0px 0px 5px rgba(255, 255, 255, 1)', mt: 4 }}
                alt="Your Name"
            />
            <Typography variant="h4" sx={{ mt: 2, fontFamily: 'jetbrains-regular'}}>
                Flavio
            </Typography>
            <Typography variant="h6" sx={{ mt: 1, fontFamily: 'jetbrains-regular' }}>
                Ceccarelli
            </Typography>
        </Box>
    );
}

export default Profile;
