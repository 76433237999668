import {Box, Typography} from '@mui/material';
import './../cursor.css'

function AboutMe({checked, setChecked, text, typedText, showCursor}) {

    // const [text, setText] = useState(undefined);
    // const [text, setText] = useState(Text.textAboutMe);
    // const [typedText, setTypedText] = useState('');
    // const [showCursor, setShowCursor] = useState(true);

    // useEffect( () => {
    //     Text.calculateAge().then(age => {
    //         if(checked){
    //             let txt = Text.textAboutMe;
    //             txt = txt.replace('[anni]', age.toString());
    //             txt = txt.replace('[nome]', Text.nome);
    //             txt = txt.replace('[cognome]', Text.cognome);
    //             txt = txt.replace('[professione]', Text.professione);
    //             txt = txt.replace('[hobby]', Text.hobby);
    //             txt = txt.replace('[passioni]', Text.passioni);
    //             txt = txt.replace('[studi]', Text.studi);
    //             txt = txt.replace('[esperienzeLavoroPassate]', Text.esperienzeLavoroPassate);
    //             txt = txt.replace('[lavoroAttuale]', Text.lavoroAttuale);
    //             txt = txt.replace('[altro]', Text.altro);
    //             setText(txt);
    //             for (let i = 0; i < txt.length; i++) {
    //                 setTimeout(() => {
    //                     setTypedText(txt.slice(0, i + 1));
    //                 }, 12 * i);
    //             }
    //         }else{
    //             let txt = TextENG.textAboutMe;
    //             txt = txt.replace('[anni]', age.toString());
    //             txt = txt.replace('[nome]', TextENG.nome);
    //             txt = txt.replace('[cognome]', TextENG.cognome);
    //             txt = txt.replace('[professione]', TextENG.professione);
    //             txt = txt.replace('[hobby]', TextENG.hobby);
    //             txt = txt.replace('[passioni]', TextENG.passioni);
    //             txt = txt.replace('[studi]', TextENG.studi);
    //             txt = txt.replace('[esperienzeLavoroPassate]', TextENG.esperienzeLavoroPassate);
    //             txt = txt.replace('[lavoroAttuale]', TextENG.lavoroAttuale);
    //             txt = txt.replace('[altro]', TextENG.altro);
    //             setText(txt);
    //             for (let i = 0; i < txt.length; i++) {
    //                 setTimeout(() => {
    //                     setTypedText(txt.slice(0, i + 1));
    //                 }, 12 * i);
    //             }
    //         }
    //     });
    //
    //
    // }, []);

    return (
        text === undefined ? null : (
            <Box sx={{textAlign: 'center', my: 4}}>
                <Typography variant="h3" sx={{mb: 2, fontFamily: 'jetbrains-bold'}}>
                    {checked ? 'Chi sono' : 'About me'}<span className="cursor" style={{opacity: showCursor ? 1 : 0, height: '40px'}}/>
                </Typography>
                {/*<Typography variant="body1" sx={{fontFamily: 'jetbrains-regular'}}>*/}
                {/*    Ciao, sono [il tuo nome completo], [la tua età] anni, [la tua professione o ambito di lavoro]. Sono appassionato di [le tue passioni o interessi principali], e amo [attività o hobby]. Ho lavorato come [esperienze di lavoro o formazione] e ora mi dedico a [la tua attuale attività professionale o di studio].*/}
                {/*</Typography>*/}
                <Typography variant="body1" sx={{fontFamily: 'jetbrains-regular'}}>
                    {/*{typedText}*/}
                    {text}
                    <span className="cursor" style={{opacity: showCursor ? 1 : 0}}/>
                    {/*{typedText !== text ? (*/}
                    {/*    <span className="cursor" style={{opacity: showCursor ? 1 : 0}}/>*/}
                    {/*) : null}*/}
                </Typography>
            </Box>
        )
    );
}

export default AboutMe;
