import './App.css';
import {
    Backdrop,
    Box, CircularProgress,
    Container,
    CssBaseline,
    Grow,
} from "@mui/material";
import Profile from "./components/Profile";
import AboutMe from "./components/AboutMe";
import SocialContacts from "./components/SocialContacts";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import './font.css';
import MyProjects from "./components/MyProjects";
import Projects from "./utility/Projects";
import {useEffect, useState} from "react";
import LanguageSwitch from "./components/LanguageSwitch";
import Text from "./utility/Text";
import TextENG from "./utility/TextENG";
import ProjectsENG from "./utility/ProjectsENG";
import IphoneMockupMyProjects from "./components/IphoneMockupMyProjects";
import IphoneMockupSocial from "./components/IphoneMockupSocial";

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const websites = [
    {
        name: 'Sito 1',
        icon: '/path_to_icon1.png',
        url: 'https://sito1.com',
    },
    {
        name: 'Sito 2',
        icon: '/path_to_icon2.png',
        url: 'https://sito2.com',
    },
    // Aggiungi più siti web qui...
];

function App() {

    const [checked, setChecked] = useState(undefined);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [text, setText] = useState(undefined);
    const [typedText, setTypedText] = useState('');
    const [showCursor, setShowCursor] = useState(true);

    const updateAboutMeText = (check) => {
        Text.calculateAge().then(age => {
            if(check){
                let txt = Text.textAboutMe;
                txt = txt.replace('[anni]', age.toString());
                txt = txt.replace('[nome]', Text.nome);
                txt = txt.replace('[cognome]', Text.cognome);
                txt = txt.replace('[professione]', Text.professione);
                txt = txt.replace('[hobby]', Text.hobby);
                txt = txt.replace('[passioni]', Text.passioni);
                txt = txt.replace('[studi]', Text.studi);
                txt = txt.replace('[esperienzeLavoroPassate]', Text.esperienzeLavoroPassate);
                txt = txt.replace('[lavoroAttuale]', Text.lavoroAttuale);
                txt = txt.replace('[altro]', Text.altro);
                setText(txt);
                setTypedText(txt);
                // for (let i = 0; i < txt.length; i++) {
                //     setTimeout(() => {
                //         setTypedText(txt.slice(0, i + 1));
                //     }, 12 * i);
                // }
            }else{
                let txt = TextENG.textAboutMe;
                txt = txt.replace('[anni]', age.toString());
                txt = txt.replace('[nome]', TextENG.nome);
                txt = txt.replace('[cognome]', TextENG.cognome);
                txt = txt.replace('[professione]', TextENG.professione);
                txt = txt.replace('[hobby]', TextENG.hobby);
                txt = txt.replace('[passioni]', TextENG.passioni);
                txt = txt.replace('[studi]', TextENG.studi);
                txt = txt.replace('[esperienzeLavoroPassate]', TextENG.esperienzeLavoroPassate);
                txt = txt.replace('[lavoroAttuale]', TextENG.lavoroAttuale);
                txt = txt.replace('[altro]', TextENG.altro);
                setText(txt);
                setTypedText(txt);
                // for (let i = 0; i < txt.length; i++) {
                //     setTimeout(() => {
                //         setTypedText(txt.slice(0, i + 1));
                //     }, 12 * i);
                // }
            }
        });
    }

    useEffect(() => {
        document.fonts.ready.then(() => {
            setFontsLoaded(true);
        });

        //ABOUT ME
        const checkedLocalStorage = localStorage.getItem('checked') === null ? true : localStorage.getItem('checked') === 'true';
        // alert(localStorage.getItem('checked'));
        setChecked(checkedLocalStorage);
        Text.calculateAge().then(age => {
            if(checkedLocalStorage){
                let txt = Text.textAboutMe;
                txt = txt.replace('[anni]', age.toString());
                txt = txt.replace('[nome]', Text.nome);
                txt = txt.replace('[cognome]', Text.cognome);
                txt = txt.replace('[professione]', Text.professione);
                txt = txt.replace('[hobby]', Text.hobby);
                txt = txt.replace('[passioni]', Text.passioni);
                txt = txt.replace('[studi]', Text.studi);
                txt = txt.replace('[esperienzeLavoroPassate]', Text.esperienzeLavoroPassate);
                txt = txt.replace('[lavoroAttuale]', Text.lavoroAttuale);
                txt = txt.replace('[altro]', Text.altro);
                setText(txt);
                for (let i = 0; i < txt.length; i++) {
                    setTimeout(() => {
                        setTypedText(txt.slice(0, i + 1));
                    }, 12 * i);
                }
            }else{
                let txt = TextENG.textAboutMe;
                txt = txt.replace('[anni]', age.toString());
                txt = txt.replace('[nome]', TextENG.nome);
                txt = txt.replace('[cognome]', TextENG.cognome);
                txt = txt.replace('[professione]', TextENG.professione);
                txt = txt.replace('[hobby]', TextENG.hobby);
                txt = txt.replace('[passioni]', TextENG.passioni);
                txt = txt.replace('[studi]', TextENG.studi);
                txt = txt.replace('[esperienzeLavoroPassate]', TextENG.esperienzeLavoroPassate);
                txt = txt.replace('[lavoroAttuale]', TextENG.lavoroAttuale);
                txt = txt.replace('[altro]', TextENG.altro);
                setText(txt);
                for (let i = 0; i < txt.length; i++) {
                    setTimeout(() => {
                        setTypedText(txt.slice(0, i + 1));
                    }, 12 * i);
                }
            }
        });
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            {fontsLoaded && checked !== undefined ? (
                <Box sx={{flexGrow: 1}}>
                    {/*<AppBar position="sticky">*/}
                    {/*  <Toolbar>*/}
                    {/*    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>*/}
                    {/*      Flavio Ceccarelli*/}
                    {/*    </Typography>*/}
                    {/*  </Toolbar>*/}
                    {/*</AppBar>*/}
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                        <Container maxWidth="sm">
                            <Box sx={{my: 4}}>
                                <LanguageSwitch checked={checked} setChecked={setChecked} updateAboutMeText={updateAboutMeText} isWritingAboutMe={typedText !== text}/>
                                <Profile/>
                                <AboutMe checked={checked} setChecked={setChecked} showCursor={showCursor} text={text} typedText={typedText}/>
                                <IphoneMockupMyProjects showCursor={showCursor} projects={checked ? Projects.projects : ProjectsENG.projects} checked={checked}/>
                                {/*<MyProjects projects={checked ? Projects.projects : ProjectsENG.projects} checked={checked}/>*/}
                                {/*<SocialContacts checked={checked}/>*/}
                                <IphoneMockupSocial showCursor={showCursor} checked={checked}/>
                            </Box>
                        </Container>
                    </Grow>
                </Box>
            ) : (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    onClick={() => {}}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </ThemeProvider>
    );
}

export default App;
